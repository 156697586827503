import React from 'react'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Colors } from '../../../config'
export const RadioBox = (props) => {
    const styles = StyleSheet.create({
        container: {
            width: 28,
            height: 28,
            borderColor: "#2D70F370",
            borderWidth: 3,
            borderRadius: 15,
        },
        checkedState: {
            width: 15,
            height: 15,
            backgroundColor: global.Colors.primary,
            borderRadius: 15,
            marginTop: 3.5,
            marginLeft: 3.5
        }
    })
    return (
        <TouchableOpacity onPress={() => props.actionPress(props.valueReturn)}>
            <View style={styles.container}>
                {props.checked ? <View style={styles.checkedState}></View> : null}
            </View>
        </TouchableOpacity>
    )
}
export const CheckBox = (props) => {
    const styles = StyleSheet.create({
        CheckBox: {
            width: 26,
            height: 26,
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 8,
            borderColor: "#2D70F395",
            borderWidth: 2,
            borderRadius: 20,
            backgroundColor: props.checked ? global.Colors.primary : "#25242302"
        }
    })
    return (
        <TouchableOpacity onPress={() => props.actionPress(props.valueReturn)}>
            <View>
                <View style={styles.CheckBox}>
                    {props.checked ? <Text style={{ color: "#FFF", fontWeight: "700", fontSize: 11 }}>✓</Text> : <Text></Text>}
                </View>
            </View>
        </TouchableOpacity>
    )
}
export const SubmitBtn = (props) => {
    const styles = StyleSheet.create({
        Botton: {
            backgroundColor: props.bdgClr,
            alignItems: "center",
            borderRadius: 30,
            ...(props.customStyle ? props.customStyle :
                {
                    paddingHorizontal: 30,
                    marginVertical: 12,
                    width: "100%",
                    paddingVertical: 10
                }),

        }
    })
    return (
        <TouchableOpacity onPress={() => props.actionPress(props.valueReturn)}>
            <View>
                <View style={styles.Botton}>
                    <Text style={{ color: props.fontClr, fontWeight: "600", fontSize: 16 }}>{props.text}</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}
