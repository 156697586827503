import { homeConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
export const homeActions = {
    getHomeTest
};
function getHomeTest(){
    return dispatch => {
        dispatch(FunctionService.request(homeConstants.GET_HOME_TEST_REQUEST))
        RequestService.GetRequest('candidate/tests/home',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,homeConstants.GET_HOME_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,homeConstants.GET_HOME_TEST_FAILURE))
        })
    }
}