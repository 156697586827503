import { testevalConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
export const testevalActions = {
    getTest,
    getDetailTest,
    submitAnswer,
    initDetail
};
function initDetail(){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.REMOVE_DETAIL_TEST_REQUEST))
    }
}
function getTest(){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.GET_TEST_REQUEST))
        RequestService.GetRequest('evaluator/test/list',true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testevalConstants.GET_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testevalConstants.GET_TEST_FAILURE))
        })
    }
}
function getDetailTest(idtest){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.GET_DETAIL_TEST_REQUEST))
        RequestService.GetRequest('evaluator/evaluation/'+idtest,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testevalConstants.GET_DETAIL_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testevalConstants.GET_DETAIL_TEST_FAILURE))
        })
    }
}
function submitAnswer(answer){
    return dispatch => {
        dispatch(FunctionService.request(testevalConstants.SUBMIT_TEST_REQUEST))
        RequestService.PostRequest("evaluator/evaluation/submit",answer,true)
        .then(res=>{
            dispatch(FunctionService.succes(res.data,testevalConstants.SUBMIT_TEST_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err.response,testevalConstants.SUBMIT_TEST_FAILURE))
        })
    }
}