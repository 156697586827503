import React, { Component } from 'react';
import { Text, ScrollView, View, StyleSheet, Alert, TouchableOpacity } from 'react-native'
import { testevalActions } from '../../../actions'
import { connect } from 'react-redux'
import { Loader, SubmitBtn, CheckElement, CheckIcon, WarningSvg } from '../_Common'
import { ElementList, CandidateTop } from './ElementList'
import { QestionEvalu } from './QestionEvalu'
class AnswerEvaluTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: 1,
            nbPage: 10,
            answerAll:  (props.dataDetailEval && Array.isArray(props.dataDetailEval.answers)) ? props.dataDetailEval.answers : [],
            currentSection: 0
        }
        this.refTopScroll = React.createRef()
        this.scrollBack = React.createRef()
        this.answers = (props.dataDetailEval && Array.isArray(props.dataDetailEval.answers)) ? props.dataDetailEval.answers : []
        this.answersTemp = null
        this.sizeSections = 0
        this._handleScrool = this._handleScrool.bind(this)
        this._handleSectionChange = this._handleSectionChange.bind(this)
        this._handleAnswer = this._handleAnswer.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.RelaodTest = this.RelaodTest.bind(this)
        this.testSubmitValiderButton = this.testSubmitValiderButton.bind(this)
        props.getDetailTest(props.match.params.idtest)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getingDetailEval, dataDetailEval, submitinAnswer } = nextProps
        if (this.props.getingDetailEval === 0 && getingDetailEval === 1 && dataDetailEval && Array.isArray(dataDetailEval.answers)) {
        
            if (this.answersTemp) {
                let dt = [...dataDetailEval.answers]
                dataDetailEval.answers.forEach((el, ind1) => {
                    el.groupes.forEach((grp, ind2) => {
                        grp.questions.forEach((qst, ind3) => {
                            qst.detailanswer.forEach((dtanswer, ind4) => {
                                const dtt = this.answersTemp[ind1].groupes[ind2].questions[ind3].detailanswer.find((elmm) => elmm.candidate_id === dtanswer.candidate_id)
                                if(dtt && dtt.answerDetail[0].reponse !== null) {
                                    dt[ind1].groupes[ind2].questions[ind3].detailanswer[ind4].answerDetail = dtt.answerDetail
                                }
                            })
                        })
                    })
                })
                this.answers = dt
                this.setState({
                    answerAll: dt
                })
                
                this.answers = dt
                this.setState({
                    answerAll: dt
                })
            } else {
                
                this.answers = dataDetailEval.answers
                this.setState({
                    answerAll: dataDetailEval.answers
                })
            }
        
            this.sizeSections = dataDetailEval.sections.length
        }
        if (this.props.submitinAnswer === 0 && submitinAnswer === 2) {
            Alert.alert("Problème de connexion internet", "š'il veut plaît rester dans la page de reponse et essayer de renvoie votre évaluation")
        }
    }
    _handleSectionChange(value) {
        this.scrollBack.scrollTo({ x: 0, y: 0 })
        this.setState({
            currentSection: this.state.currentSection + value,
            currentGroupe: 0
        })
        this.scrollBack.scrollTo({ x: 0, y: 0 })
    }
    _handleScrool(e) {
        this.refTopScroll.scrollTo({ x: e.nativeEvent.contentOffset.x, y: e.nativeEvent.contentOffset.y, animated: false });
    }
    _handleSectionChose(key) {
        this.scrollBack.scrollTo({ x: 0, y: 0 })
        this.setState({
            currentSection: key
        })
        this.scrollBack.scrollTo({ x: 0, y: 0 })
    }
    _handleAnswer(item) {
        let lengDetail = this.answers[this.state.currentSection].groupes[item.groupe].questions[item.qst].detailanswer[item.candi].answerDetail.length
        for (var i = 0; i < lengDetail; i++) {
            this.answers[this.state.currentSection].groupes[item.groupe].questions[item.qst].detailanswer[item.candi].answerDetail[i].reponse = false
        }
        this.answers[this.state.currentSection].groupes[item.groupe].questions[item.qst].detailanswer[item.candi].answerDetail[item.dtl].reponse = item.response
        this.answersTemp = this.answers
    }
    handleSubmit() {
        for (var k = 0; k < this.answers.length; k++) {
            let tmpSection = this.answers[k].groupes
            for (var d = 0; d < tmpSection.length; d++) {
                let tmpQstion = tmpSection[d].questions
                for (var i = 0; i < tmpQstion.length; i++) {
                    for (var j = 0; j < tmpQstion[i].detailanswer.length; j++) {
                        let filterAnser = tmpQstion[i].detailanswer[j].answerDetail.filter(elem => elem.reponse)
                        if (filterAnser.length === 0) {
                            this.scrollBack.scrollTo({ x: 0, y: 0 })
                            this.setState({
                                currentSection: k
                            })
                            this.scrollBack.scrollTo({ x: 0, y: 0 })
                            return 0
                        }
                    }
                }
            }

        }
        let resultSubmit = {
            evaluation_evaluator_id: this.props.dataDetailEval.evaluation_evaluator_id,
            sections: this.answers
        }
        this.props.submitAnswer(resultSubmit)
    }
    RelaodTest() {
        
        this.props.getDetailTest(this.props.match.params.idtest || this.props.route.params.idtest)
    }
    testSubmitValiderButton() {
        
        // console.log(' 🚀  => this.answersTemp:', this.answersTemp)
        // console.log(' 🚀  => this.props.dataDetailEval.answers:', this.props.dataDetailEval.answers)
        // console.log(' 🚀  => dt:', dt)
        // let dt = this.props.dataDetailEval.answers
        // this.props.dataDetailEval.answers.forEach((el, ind1) => {
        //     el.groupes.forEach((grp, ind2) => {
        //         grp.questions.forEach((qst, ind3) => {
        //             qst.detailanswer.forEach((dtanswer, ind4) => {
        //                 const dtt = this.answersTemp[ind1].groupes[ind2].questions[ind3].detailanswer.find((elmm) => elmm.candidate_id === dtanswer.candidate_id)
        //                 if(dtt.answerDetail[0].reponse !== null) {
        //                     dt[ind1].groupes[ind2].questions[ind3].detailanswer[ind4].answerDetail = dtt.answerDetail
        //                 }
        //             })
        //         })
        //     })
        // })
        // this.answers = dt
        // this.setState({
        //     answerAll: dt
        // })

        let resultSubmit = {
            evaluation_evaluator_id: this.props.dataDetailEval.evaluation_evaluator_id,
            sections: this.answers
        }
        console.log(' 🚀  => resultSubmit:', resultSubmit)


    }
    render() {
        const { getingDetailEval, submitinAnswer, dataDetailEval } = this.props
        let sections = []
        let groupes = []
        let candidates = []
        let nbCandi = []
        let indexSticky = []
        if (getingDetailEval === 1 && dataDetailEval) {
            candidates = dataDetailEval.candidates
            sections = dataDetailEval.sections
            nbCandi = dataDetailEval.nbCandidate
            if (dataDetailEval.sections[this.state.currentSection]) {
                groupes = Array.isArray(dataDetailEval.sections[this.state.currentSection].groupes) ? dataDetailEval.sections[this.state.currentSection].groupes : []
                for (var i = 0; i < groupes.length; i++) {
                    indexSticky.push(i * 2)
                }
            }
        }
        return (
            <View style={{ flex: 1, height: "100%" }}>{getingDetailEval === 0 ? <Loader /> :
                (dataDetailEval && (dataDetailEval.message === "NO" || dataDetailEval.message === "YES")) ? dataDetailEval.message === "NO" ?
                    <>
                        {submitinAnswer === 0 && <Loader />}
                        <View style={styles.containerSize}>
                            <View style={{ width: "25%" }}>
                                <View style={{ flex: 1, flexDirection: 'row' }}>
                                    <View style={styles.goBackContainer}>
                                        <SubmitBtn actionPress={this.props.history.goBack} customStyle={{ paddingHorizontal: 10, marginVertical: 8, width: "100%", paddingVertical: 8, borderRadius: 5 }} text="Quitter l'évaluation" bdgClr="#F64E6090" />
                                    </View>
                                    <View style={styles.goBackContainer}>
                                        <SubmitBtn actionPress={this.RelaodTest} customStyle={{ paddingHorizontal: 30, marginVertical: 8, width: "100%", paddingVertical: 8, borderRadius: 5, color: '#000' }} text="Actualiser" bdgClr="#159990" fontClr='#fff' />
                                        {/* <SubmitBtn actionPress={this.testSubmitValiderButton} customStyle={{ paddingHorizontal: 30, marginVertical: 8, width: "100%", paddingVertical: 8, borderRadius: 5, color: '#000' }} text="Valider" bdgClr="#159990" /> */}
                                    </View>
                                </View>
                            </View>
                            <ScrollView showsHorizontalScrollIndicator={false} horizontal={true} ref={ref => (this.refTopScroll = ref)}>
                                <View style={styles.containerTop}>
                                    {candidates.map((candi, key) => {
                                        return <CandidateTop key={key} item={{ text: candi.firstname + " " + candi.lastname, photo: candi.photo }} />
                                    })}
                                </View>
                            </ScrollView>
                            {/* <View style={{ width: "160px", border:'1px solid black' }}>
                            </View> */}
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false} stickyHeaderIndices={indexSticky} ref={ref => (this.scrollBack = ref)}>
                            {groupes.map((grp, key2) => {
                                return [<View key={key2} style={styles.titleGroupe}>
                                    <View>
                                        <Text style={styles.textGroupe}>{grp.groupe_name}</Text>
                                    </View>
                                </View>, <View>
                                    {Array.isArray(grp.questions) && grp.questions.map((qst, key) => {
                                        return <QestionEvalu qst={qst} candidates={candidates} answers={this.state.answerAll[this.state.currentSection].groupes[key2].questions[key]} handleAnswer={this._handleAnswer} nbCandi={nbCandi} key={key} groupe={key2} index={key} handleScroll={this._handleScrool} />
                                    })}
                                </View>]
                            })}
                            <View style={styles.flexBtn}>
                                {this.state.currentSection > 0 && <SubmitBtn actionPress={() => this._handleSectionChange(-1)} text="Séquence précedent" bdgClr="#2D70F320" fontClr={global.Colors.primary} />}
                                <SubmitBtn actionPress={this.state.currentSection === (this.sizeSections - 1) ? this.handleSubmit : () => this._handleSectionChange(1)} text={this.state.currentSection === (this.sizeSections - 1) ? "Envoyer" : "Séquence suivant"} bdgClr={global.Colors.primary} fontClr="#FFFFFF" />
                            </View>
                        </ScrollView>
                        <View style={styles.bottomContain}>
                            <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                                {sections.map((section, key) => {
                                    return <ElementList key={key} item={{ text: section.section_name, key: key, active: this.state.currentSection === key }} iterableProps={{ onPress: () => this._handleSectionChose(key) }} />
                                })}
                            </ScrollView>
                        </View>
                    </> :
                    <CheckElement Icon={<CheckIcon width="150" height="150" />}>
                        <Text style={{ fontSize: 25, marginTop: 20, marginBottom: 10, fontWeight: "700", maxWidth: "70%", textAlign: "center" }}>Votre évaluation est terminée ! Merci pour votre participation et à très bientôt ! 😀</Text>
                        <SubmitBtn actionPress={() => this.props.history.goBack()} text="Retour à l'accueil" bdgClr="#2D70F320" fontClr={global.Colors.primary} />
                    </CheckElement> :
                    <CheckElement Icon={<WarningSvg width="150" height="150" />}>
                        <Text style={{ fontSize: 25, marginTop: 20, marginBottom: 10, fontWeight: "700", maxWidth: "70%", textAlign: "center" }}>Une erreur se produit lors de chargement de test, essayer de le recharger a nouveau</Text>
                        <SubmitBtn actionPress={this.RelaodTest} text="Recharger" bdgClr="#2D70F320" fontClr={global.Colors.primary} />
                    </CheckElement>
            }
            </View>
        )
    }
}
const styles = StyleSheet.create({
    goBackContainer: {
        width: "230px",
        height: "100%",
        // marginRight: '30px',
        alignItems: "center",
        justifyContent: "center"
    },
    bottomContain: {
        backgroundColor: global.Colors.primary
    },
    flexBtn: {
        flexDirection: "row",
        justifyContent: "space-around"
    },
    titleGroupe: {
        flexDirection: "row",
        paddingHorizontal: 30,
        marginBottom: 15,
        paddingVertical: 8,
        backgroundColor: global.Colors.primary,
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.3,
        shadowRadius: 10,
        elevation: 6
    },
    textGroupe: {
        fontWeight: "700",
        fontSize: 20,
        textAlign: "center",
        color: "#FFFFFF"
    },
    textQestion: {
        fontSize: 23,
        fontWeight: "700",
        color: "#000000"
    },
    containerQst: {
        paddingVertical: 15,
        paddingHorizontal: 20,
        backgroundColor: "#FFFFFF"
    },
    containerSize: {
        flexDirection: "row",
        paddingVertical: '5px',
        backgroundColor: "#FFFFFF",
        borderBottomWidth: 2,
        borderBottomColor: global.Colors.primary
    },
    containerTop: {
        flexDirection: "row",
    },
    elemList: {
        width: "25%"
    },
    optionQst: {
        marginVertical: 10
    }
})
function mapState(state) {
    const { getingDetailEval, dataDetailEval, submitinAnswer, dataSubmit } = state.testeval
    return { getingDetailEval, dataDetailEval, submitinAnswer, dataSubmit }
}
const actionCreators = {
    getDetailTest: testevalActions.getDetailTest,
    submitAnswer: testevalActions.submitAnswer,
    initDetail: testevalActions.initDetail
};

const connectedClass = connect(mapState, actionCreators)(AnswerEvaluTest);
export { connectedClass as AnswerEvaluTest };

