import { answerEvaluConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
export const answerEvaluActions = {
    StoreAnswer,
    initAnswer
};
function StoreAnswer(answers){
    return dispatch => {
        dispatch(FunctionService.succes(answers,answerEvaluConstants.STORE_ANSWERS))
    }
}
function initAnswer(data){
    return dispatch => {
        dispatch(FunctionService.succes(data,answerEvaluConstants.INIT_ANSWERS))
    }
}