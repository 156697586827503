import React from 'react'
import { View, Text, StyleSheet, TouchableHighlight, TouchableOpacity, TextInput, Image } from 'react-native'
import { CheckSvg, UserEmptySvg } from '../_Common'
export const ElementList = ({ item, active, iterableProps }) => {
    return (
        <TouchableHighlight underlayColor="#FFFFFF20" {...iterableProps}>
            <View style={[styles.SectionBtm, item.active ? styles.activeBtm : {}]}>
                <Text style={styles.textBottom}>{item.text}</Text>
            </View>
        </TouchableHighlight>
    )
}
export const ElementCandi = ({ item, action }) => {
    return (
        <View style={styles.SectionElem}>
            <Text style={styles.textCand}>{item.text}</Text>
        </View>
    )
}
export const CandidateTop = ({ item }) => {
    return (
        <View style={{ width: 200, flexDirection: "column", alignItems: "center", justifyContent: "space-around" }}>
            {item.photo ?
                <Image
                    source={{ uri: item.photo }}
                    style={{ width: 50, height: 50, borderRadius: 70, marginBottom: 0 }}
                /> : <UserEmptySvg width="50" height="50" />}
            <Text style={styles.textTopCand}>{item.text}</Text>
        </View>
    )
}
export const ChooseAnswer = (props) => {
    return (
        <TouchableOpacity onPress={() => props.action(props.item)}>
            <View style={[styles.answerContain, props.item.active ? styles.activeAnswer : {}]}>
                {props.item.active && <CheckSvg width="30" height="30" />}
            </View>
        </TouchableOpacity>
    )
}
export const TextAnswer = (props) => {
    return (
        <View style={{ width: 970, marginVertical: 5 }}>
            <TextInput
                onChangeText={text => props.action(props.item, text)}
                value={props.item.text}
                style={styles.textArea}
                placeholder="Écrire votre texte ici ..."
                multiline={true}
                numberOfLines={10}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    answerContain: {
        minHeight: 40,
        backgroundColor: "#2D70F315",
        borderRadius: 7,
        marginHorizontal: 30,
        width: 140,
        justifyContent: "center",
        alignItems: "center"
    },
    textArea: {
        borderColor: "#2D70F380",
        fontWeight: "600",
        backgroundColor: "#FFFFFF",
        fontSize: 19,
        height: 80,
        width: 970,
        marginHorizontal: 5,
        borderWidth: 2,
        textAlignVertical: 'top',
        padding: 10,
        borderRadius: 7
    },
    activeAnswer: {
        borderWidth: 4,
        borderColor: "#2D70F395"
    },
    SectionElem: {
        paddingHorizontal: 30,
        marginVertical: 10,
        paddingVertical: 10
    },
    SectionBtm: {
        paddingHorizontal: 30,
        paddingVertical: 12,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center"
    },
    activeBtm: {
        borderTopWidth: 2,
        borderTopColor: "#21b876",
        backgroundColor: "#21b87690"
    },
    textBottom: {
        fontWeight: "600",
        fontSize: 17,
        color: "#FFFFFF",
        textTransform: 'capitalize'
    },
    textCand: {
        fontWeight: "600",
        fontSize: 17
    },
    textTopCand: {
        fontWeight: "700",
        fontSize: 15,
        textAlign: "center"
    }
})